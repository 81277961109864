import React, { useState, useEffect } from "react";
import { Avatar, Checkbox } from "antd";
import { Dropdown } from "../../../../components";
import { PROJECT_ROLES_OPTIONS } from "../../../../constants";
import { useSelector } from "react-redux";
import { updateProjectUserRequest } from "../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../helpers";
import clsx from "clsx";

const UserDetail = ({ data, isOwner, selectUser, isSelected }) => {
  // STATES
  const [selectedRole, setselectedRole] = useState(PROJECT_ROLES_OPTIONS[0]);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.selectedProject);
  const users = useSelector(({ project }) => project.projectUsers);

  // CUSTOM DISPATCH
  const [updateRole] = CustomDispatch(updateProjectUserRequest);

  // HELPERS
  const updateRoleHandler = (role) => {
    setselectedRole(role);
    const payload = {
      resource: "access",
      method: "update",
      details: {
        project_id: projectData.id,
        users: [
          {
            user_id: data?.user_id,
            role: role.value,
          },
        ],
      },
    };
    updateRole({
      payload,
    });
  };

  // HOOKS
  useEffect(() => {
    if (!data) return;
    const role = PROJECT_ROLES_OPTIONS.find((x) => x.value === data?.role);
    setselectedRole(role);
  }, [data]);

  return (
    <div className={clsx("user-detail", users.length >= 3 && "overflow")}>
      <span className="select-box">
        {!isOwner && (
          <Checkbox
            checked={isSelected}
            onClick={() => selectUser(data?.user_id)}
          />
        )}
      </span>
      <Avatar className="thumb" src={data?.photo_url}>
        {data?.name?.charAt(0) ?? data?.user_id?.charAt(0)}
      </Avatar>
      <button className="detail" onClick={() => selectUser(data?.user_id)}>
        <h4>{data?.name ?? data?.user_id}</h4>
        <p>{data?.email ?? "-"}</p>
      </button>
      {data?.role === "owner" ? (
        <span className="owner-tag">Owner</span>
      ) : (
        <Dropdown
          className="user-permission"
          selectedVal={selectedRole.label}
          selectHandler={(val) => updateRoleHandler(val)}
          options={PROJECT_ROLES_OPTIONS}
          optionBox={({ label }) => label}
        />
      )}
    </div>
  );
};

const SharedList = ({
  selectedUsers,
  setselectedUsers,
  removeUserPreviewHandler,
}) => {
  // REDUX DATA
  const users = useSelector(({ project }) => project.projectUsers);

  // CONST VALS
  const owners = users?.filter((x) => x.role === "owner");
  const collaborators = users?.filter((x) => x.role !== "owner");

  // HANDLERS
  const selectAllUserHandler = () => {
    if (selectedUsers.length === collaborators.length)
      return setselectedUsers([]);
    setselectedUsers(collaborators.map((user) => user.user_id));
  };

  const selectUserHandler = (userid) => {
    if (selectedUsers.includes(userid))
      return setselectedUsers(selectedUsers.filter((x) => x !== userid));
    setselectedUsers([...selectedUsers, userid]);
  };

  return (
    <div className="shared-list customize-form">
      <div className="top-box">
        {collaborators.length > 0 && (
          <>
            <Checkbox
              checked={selectedUsers.length === collaborators.length}
              onChange={selectAllUserHandler}
            >
              Select all
            </Checkbox>
            <button
              className="remove-btn"
              disabled={selectedUsers.length <= 0}
              onClick={removeUserPreviewHandler}
            >
              Remove selected
            </button>
          </>
        )}
      </div>
      <div className={clsx("users-listing", users.length >= 3 && "scrollable")}>
        {owners?.map((user, index) => (
          <UserDetail
            data={user}
            key={index}
            isOwner
            selectUser={selectUserHandler}
            isSelected={selectedUsers.includes(user.user_id)}
          />
        ))}
        {collaborators?.map((user, index) => (
          <UserDetail
            data={user}
            key={index}
            selectUser={selectUserHandler}
            isSelected={selectedUsers.includes(user.user_id)}
          />
        ))}
      </div>
    </div>
  );
};

export default SharedList;
