import { createSlice } from "@reduxjs/toolkit";

const UserReducer = createSlice({
  name: "user",
  initialState: {
    data: {},
    groupAccess: false,
  },
  reducers: {
    // GET USER DETAILS
    getUserDetailsRequest() {},
    getUserDetailsSuccess(state, { payload }) {
      state.data = payload;
      state.groupAccess = payload.group_admin;
    },
  },
});

export const { getUserDetailsRequest, getUserDetailsSuccess } =
  UserReducer.actions;

export default UserReducer.reducer;
