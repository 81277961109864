import React, { useState } from "react";
import { Avatar, Form, Input } from "antd";
import { CustomDispatch } from "../../../../helpers";
import { getUserDetailsRequest } from "../../../../redux/slicers/user";
import { Button, Dropdown } from "../../../../components";
import { getUserAsurite, toastAlert } from "../../../../utils";
import { ALERT_TYPES, PROJECT_ROLES_OPTIONS } from "../../../../constants";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faPaperclip,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import clsx from "clsx";
import { addProjectUserRequest } from "../../../../redux/slicers/project";

const AccessDetail = () => {
  // STATES
  const [isCopied, setCopied] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const users = useSelector(({ project }) => project.projectUsers);

  // CUSTOM DISPATCH
  const [getUserDetail, userLoader] = CustomDispatch(getUserDetailsRequest);
  const [addUser, addLoader] = CustomDispatch(addProjectUserRequest);

  // CONST VALS
  const [form] = Form.useForm();
  const userAsurite = getUserAsurite();

  // HELPERS
  const getUserDetailHelper = async (asurites) => {
    const newusers = asurites.replace(/\s*,\s*/g, ",");
    const userslist = newusers.split(",");
    let updatedList = [];
    userslist.forEach((user) => {
      if (user === userAsurite) {
        toastAlert("You can't add yourself", ALERT_TYPES.ERROR);
        return;
      }
      if (users.some((x) => x.user_id === user)) {
        toastAlert(
          `User with ASURITE ${user} already exist`,
          ALERT_TYPES.ERROR
        );
        return;
      }
      updatedList.push(user);
    });
    if (updatedList.length <= 0) return;
    const payload = {
      resource: "user",
      method: "describe",
      details: {
        users: updatedList,
      },
    };
    getUserDetail({
      payload,
      success(data) {
        updatedList.forEach((user) => {
          if (!data?.some((x) => x.id === user)) {
            toastAlert(
              `User with ASURITE ${user} not found`,
              ALERT_TYPES.ERROR
            );
          }
        });
        if (data?.length === 0) return;
        form.resetFields();
        selectUserHandler(data);
      },
    });
  };

  const inviteUserHandler = ({ viewer }) => {
    getUserDetailHelper(viewer);
  };

  // HANDLERS
  const copyHandler = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const selectUserHandler = (users) => {
    setSelectedUsers(
      users.map((user) => {
        return {
          thumb: user.photo_url,
          user_id: user.id,
          role: PROJECT_ROLES_OPTIONS[0].value,
          name: user.name,
          email: user.email,
        };
      })
    );
  };

  const selectUserRole = (role, id) => {
    let users = [...selectedUsers];
    const index = users.findIndex((user) => user.user_id === id);
    users[index].role = role;
    setSelectedUsers(users);
  };

  const removeSelectedUser = (id) => {
    setSelectedUsers(selectedUsers.filter((user) => user.user_id !== id));
  };

  const addUserHandler = () => {
    const users = selectedUsers.map((user) => {
      return {
        user_id: user?.user_id,
        role: user?.role,
      };
    });
    const payload = {
      resource: "access",
      method: "add",
      details: {
        project_id: data?.id,
        users,
      },
    };
    addUser({
      payload,
      success: () => setSelectedUsers([]),
    });
  };

  return (
    <>
      <Form form={form} className="invite-box" onFinish={inviteUserHandler}>
        <Form.Item
          name="viewer"
          rules={[
            {
              required: true,
              message: "Viewer cannot be empty",
            },
            () => ({
              validator(_, value) {
                if (!value) return Promise.resolve();
                if (value === userAsurite)
                  return Promise.reject(new Error("You can't add yourself"));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Add others using ASURITE (comma separated)" />
        </Form.Item>
        <Button loaderColor="#000" isLoading={userLoader}>
          <FontAwesomeIcon icon={faPlus} /> Add
        </Button>
      </Form>{" "}
      <div
        className={clsx(
          "users-detail-list",
          selectedUsers.length >= 3 && "scrollable"
        )}
      >
        {selectedUsers?.map((selectedUser, index) => (
          <div
            className={clsx(
              "user-detail",
              selectedUsers.length >= 3 && "overflow"
            )}
            key={index}
          >
            <Avatar className="thumb" src={selectedUser?.thumb}>
              {selectedUser?.name?.charAt(0) ?? selectedUser?.user_id?.charAt(0)}
            </Avatar>
            <div className="detail">
              <h4>{selectedUser?.name ?? selectedUser?.user_id}</h4>
              <p>{selectedUser?.email ?? "-"}</p>
            </div>
            <div className="user-permission">
              <Dropdown
                selectedVal={
                  PROJECT_ROLES_OPTIONS.find(
                    (role) => role.value === selectedUser?.role
                  )?.label
                }
                selectHandler={({ value }) =>
                  selectUserRole(value, selectedUser?.user_id)
                }
                options={PROJECT_ROLES_OPTIONS}
                optionBox={({ label }) => label}
              />
              <button
                className="remove-btn"
                onClick={() => removeSelectedUser(selectedUser?.user_id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="action-box">
        <CopyToClipboard text={data?.publicUrl} onCopy={copyHandler}>
          <Button boldText type="button" className="back-btn">
            <FontAwesomeIcon icon={isCopied ? faCircleCheck : faPaperclip} />
            Copy project link
          </Button>
        </CopyToClipboard>
        {selectedUsers.length > 0 && (
          <Button
            boldText
            invertedTheme
            type="submit"
            isLoading={addLoader}
            disabled={selectedUsers.length < 1}
            onClick={addUserHandler}
          >
            {/* <FontAwesomeIcon icon={faPlus} /> */}
            Invite
          </Button>
        )}
      </div>
    </>
  );
};

export default AccessDetail;
