import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
// import { Button } from "../../../../components";
import { DASHBOARD_ROUTE, PROJECT_ROUTE } from "../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUpRightFromSquare,
  faCirclePlus,
  // faCloudArrowUp,
  faCog,
  faUser,
  // faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { AccessModal } from "../";
import "./styles.scss";

const Header = ({
  chatsPreviewHandlder,
  settingPreviewHandler,
  childRoute,
}) => {
  // STATES
  const [accessPreview, setAccessPreview] = useState(false);

  // REDUX DATA
  const projectData = useSelector(({ project }) => project.selectedProject);

  // CONST VALS
  const [searchParams] = useSearchParams();
  const chatScreen = searchParams.get("chat");
  const { projectslug } = useParams();
  const navigate = useNavigate();

  // HANDLERS
  const accessPreviewHandler = () => setAccessPreview(!accessPreview);

  const newChatHandler = () => {
    const projectRoute = PROJECT_ROUTE.replace(":projectslug", projectslug);
    navigate(projectRoute);
  };

  // HOOKS
  useEffect(() => {
    // const handleUnload = (event) => {
    //   event.preventDefault();
    //   event.returnValue = "";
    //   return "";
    // };
    // window.addEventListener("beforeunload", handleUnload);
    // return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);
  return (
    <header className="project-header">
      <div className="detail-box">
        <Link
          to={
            childRoute
              ? PROJECT_ROUTE.replace(":projectslug", projectslug)
              : DASHBOARD_ROUTE
          }
          className="back-btn"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span>
            Back to {childRoute ? projectData?.projectName : "Dashboard"}
          </span>
        </Link>
        {!childRoute && (
          <>
            <h4>{projectData?.projectName}</h4>
            {chatScreen && (
              <button className="new-chat" onClick={newChatHandler}>
                <span className="thumb">
                  <FontAwesomeIcon icon={faCirclePlus} />
                </span>
                <span className="title">New Chat</span>
              </button>
            )}
          </>
        )}
      </div>
      {!childRoute && (
        <div className="action-box">
          <button className="access-btn" onClick={accessPreviewHandler}>
            <FontAwesomeIcon icon={faUser} />
            <span>Share</span>
          </button>
          {projectData?.webInterface && (
            <a
              href={projectData?.publicUrl}
              target="_blank"
              rel="noreferrer"
              className="view-app"
            >
              <span>View app</span>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          )}
          <button className="setting-btn" onClick={chatsPreviewHandlder}>
            <FontAwesomeIcon icon={faComment} />
          </button>
          <button className="setting-btn" onClick={settingPreviewHandler}>
            <FontAwesomeIcon icon={faCog} />
          </button>
          {/* <Button invertedTheme className="save-btn">
          Save Changes <FontAwesomeIcon icon={faCloudArrowUp} />
        </Button> */}
        </div>
      )}
      <AccessModal
        preview={accessPreview}
        previewHandler={accessPreviewHandler}
      />
    </header>
  );
};

export default Header;
