import React, { useState } from "react";
import { PROJECT_SECTIONS } from "../../../../constants";
import CreateProjectListing from "./create-project-listing";
import ProjectsListing from "./projects-listing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input } from "antd";

const ProjectSection = ({
  section,
  title,
  desc,
  projectRoles,
  isLoading,
  projects,
  navigateToProject,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  //Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  return (
    <>
      <div className="intro-box borderless">
        <div className="search-bar-container customize-form">
          <h1>{title}</h1>
          <Input
            prefix={<FontAwesomeIcon className="thumb" icon={faSearch} />}
            type="text"
            placeholder="Search projects here..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <p className="section-description">{desc}</p>
      </div>
      {section === PROJECT_SECTIONS.CREATE ? (
        <CreateProjectListing data={projects} />
      ) : (
        <ProjectsListing
          section={section}
          projectRoles={projectRoles}
          projects={projects}
          isLoading={isLoading}
          navigateToProject={navigateToProject}
          searchQuery={searchQuery}
        />
      )}
    </>
  );
};

export default ProjectSection;
