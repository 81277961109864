import Draggable from "react-draggable";
import { useEffect, useRef, useState } from "react";
import { Input, Popover } from "antd";
import { ResizableBox } from "react-resizable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faSquare } from "@fortawesome/free-solid-svg-icons";
import { generateGuid, getAppEnv } from "../../utils";
import { WebSocketHandlers } from "../../services/web-sockets";
import Loader from "../loader";
import Markdown from "../markdown";
import Button from "../button";
import Images from "../../theme/Images";
import "./styles.scss";
import { CustomDispatch } from "../../helpers";
import { createChatRequest } from "../../redux/slicers/chat";

const DefaultPopover = () => <span className="popover default">Ask Me!</span>;

function ChatPopover() {
  return (
    <ResizableBox
      width={400}
      height={350}
      minConstraints={[300, 250]}
      maxConstraints={[500, 450]}
      resizeHandles={["se", "sw", "ne", "nw"]}
    >
      <ChatUI />
    </ResizableBox>
  );
}

function ChatUI() {
  // STATES
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const [sessionId, setSessionId] = useState(null);

  // CUSTOM DISPATCH
  const [createChat, chatLoader] = CustomDispatch(createChatRequest);

  // CONST VALS
  const scrollTarget = useRef(null);
  const { sendJsonMessage, getWebSocket, lastMessage } = WebSocketHandlers();
  const projectId = getAppEnv().projectId;
  const isGenerating = messages.length > 0 && messages.slice(-1)[0].loading;
  const isBtnDisabled = (!query || chatLoader) && !isGenerating;

  // HELPERS
  const sendMessageHelper = (message, session) => {
    const messagePayload = {
      action: "queryV2",
      project_id: projectId,
      session_id: session ?? sessionId,
      prompt: message,
      response_format: { type: "json" },
    };

    // send message to server
    sendJsonMessage(messagePayload);
  };

  const errorHelper = (
    msg = "We encountered an issue processing your request. Please try again later."
  ) => {
    setMessages((messages) => {
      return [
        ...messages.slice(0, -1),
        {
          ...messages.slice(-1)[0],
          loading: false,
          body: msg,
        },
      ];
    });
  };

  const createChatHelper = (message) => {
    const name = message.split("\n")[0];
    const chatName = `${name.slice(0, 30)}${name.length > 30 ? "..." : ""}`;
    const payload = {
      method: "create",
      details: {
        app_id: projectId,
        session_name: chatName,
      },
    };

    createChat({
      payload,
      preventAddChat: true,
      success: (res) => {
        sendMessageHelper(message, res?.id);
        setSessionId(res?.id);
      },
      error: errorHelper,
    });
  };

  const updateResponse = (lastMessage) => {
    const data = JSON.parse(lastMessage);
    const message = data?.response;

    //end if backend sends some error.
    if ("error" in data || "message" in data) {
      errorHelper();
      return;
    }

    setMessages((messages) => {
      const updatedMessage =
        messages.slice(-1)[0].body + (message !== "<EOS>" ? message : "");
      return [
        ...messages.slice(0, -1),
        {
          ...messages.slice(-1)[0],
          loading: message !== "<EOS>",
          body: updatedMessage,
        },
      ];
    });
  };

  const stopResponseHelper = () => {
    getWebSocket().close();
    setMessages((messages) => {
      return [
        ...messages.slice(0, -1),
        {
          ...messages.slice(-1)[0],
          loading: false,
        },
      ];
    });
  };

  // HANDLERS
  const scrollToBottom = () => {
    scrollTarget.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isGenerating) {
      stopResponseHelper();
      return;
    }

    setQuery("");
    setMessages([
      ...messages,
      { id: generateGuid(), class: "user", body: query },
      { id: generateGuid(), class: "bot", loading: true, body: "" },
    ]);

    if (!sessionId) {
      createChatHelper(query);
      return;
    }

    // else send text message
    sendMessageHelper(query);
  };

  const handleClear = () => setMessages([]);

  // HOOKS
  useEffect(() => {
    if (!lastMessage) return;
    updateResponse(lastMessage.data);
  }, [lastMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-wrapper popover">
      <div className="chat-header">
        <img className="thumb" src={Images.Stars} alt="AI logo" />
        <span className="title">Ask Me!</span>
        <button onClick={handleClear} className="clear-btn">
          Clear
        </button>
      </div>
      <ul className="messages-listing">
        {messages.map((msg) => (
          <li key={msg.id} className={msg.class}>
            {!msg.body && msg.loading ? (
              <Loader size={10} color="#ffc627" height="100%" />
            ) : (
              <Markdown>{msg.body}</Markdown>
            )}
          </li>
        ))}
        <span ref={scrollTarget} className="scroll-target" />
      </ul>
      <form className="chat-footer customize-form" onSubmit={handleSubmit}>
        <Input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Questions? Ask me."
        />
        <Button disabled={isBtnDisabled} className="send-btn" invertedTheme>
          <FontAwesomeIcon icon={isGenerating ? faSquare : faArrowUp} />
        </Button>
      </form>
    </div>
  );
}

function ChatWidget() {
  // STATES
  const [isDragging, setIsDragging] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [dragStartTime, setDragStartTime] = useState(0);

  // CONST VALS
  const containerRef = useRef(null);

  // HANDLERS
  const onDrag = () => setIsDragging(true);

  const onStop = (e) => {
    setIsDragging(false);
    const dragTime = Date.now() - dragStartTime;
    if (
      dragTime < 200 &&
      Math.abs(e.clientX - e.x) < 5 &&
      Math.abs(e.clientY - e.y) < 5
    ) {
      onShowChatChange(!showChat);
    }
    localStorage.setItem(
      "widget_pos",
      JSON.stringify({
        x: -(window.innerWidth - e.clientX) + 56,
        y: -(window.innerHeight - e.clientY) + 56,
      })
    );
  };

  const onStart = () => setDragStartTime(Date.now());

  const onShowChatChange = (open) => setShowChat(open);

  return (
    <Popover
      align={{ offset: [5, -5] }}
      color="#191919"
      defaultOpen={true}
      open={!isDragging && !showChat}
      content={<DefaultPopover />}
      getPopupContainer={() => containerRef.current}
    >
      <Popover
        align={{ offset: [5, -5] }}
        color="#191919"
        open={!isDragging && showChat}
        content={<ChatPopover />}
        onOpenChange={onShowChatChange}
        trigger="click"
        getPopupContainer={() => containerRef.current}
      >
        <Draggable
          bounds="body"
          defaultPosition={
            JSON.parse(localStorage.getItem("widget_pos")) || { x: 0, y: 0 }
          }
          onStart={onStart}
          onDrag={onDrag}
          onStop={onStop}
        >
          <div ref={containerRef} className="widget-container">
            <div className="thumb">
              <img draggable="false" src={Images.AI} alt="ai widget" />
              <img
                className="move"
                draggable="false"
                src={Images.UpDownLeftRight}
                alt="draggable"
              />
            </div>
          </div>
        </Draggable>
      </Popover>
    </Popover>
  );
}

export default ChatWidget;
