import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { LabelBox } from "../../";
import { inputFieldRule } from "../../../../../../../utils";
import { useSelector } from "react-redux";
import { VISIBILITY_OPTIONS } from "../../../../../../../constants";

const AccessControl = ({ form }) => {
  // STATES
  const [groupPreview, setgroupPreview] = useState(false);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const groups = useSelector(({ groups }) => groups.groups);

  // HANDLERS
  const visbilityHandler = (value) => {
    form.setFieldsValue({
      group: undefined,
    });
    const isShared = value === VISIBILITY_OPTIONS[1].value;
    setgroupPreview(isShared);
  };
  const groupHandler = () => {
    form.setFieldsValue({ editors: undefined });
  };

  // Sort groups alphabetically
  const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name));

  // HOOKS
  useEffect(() => {
    const isShared = data.visibility === VISIBILITY_OPTIONS[1].value;
    setgroupPreview(isShared);
  }, [data]);

  return (
    <>
      <p className="sub-description">
        Restricts user access to specific features and data within your
        application based on their roles and permissions.
      </p>
      <LabelBox
        title="Visibility"
        name="visibility"
        message="Choose the privacy level for this project."
      />
      <Form.Item
        name="visibility"
        rules={inputFieldRule({
          name: "Visibility",
          isWhiteSpace: false,
        })}
      >
        <Select
          id="visibility"
          onChange={visbilityHandler}
          options={VISIBILITY_OPTIONS}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select visibility"
        />
      </Form.Item>
      {/* GROUP */}
      <LabelBox
        title="Group"
        name="group"
        message="Select the group with which the project is associated. Users from the group can be assigned as collaborators to help mange, build and edit this project."
      />
      <Form.Item
        name="group"
        rules={inputFieldRule({
          name: "Group",
          isRequired: groupPreview,
          isWhiteSpace: false,
        })}
      >
        <Select
          id="group"
          disabled={!groupPreview}
          onChange={groupHandler}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select group"
        >
          {sortedGroups.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default AccessControl;
