import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  Button,
  ConfirmationModal,
  PageTitle,
  Table,
  Slider,
} from "../../../components";
import { CreateGroup } from "./components";
import { CustomDispatch } from "../../../helpers";
import {
  deleteGroupRequest,
  editGroupRequest,
  getGroupsRequest,
} from "../../../redux/slicers/groups";
import {
  ERROR_ROUTE,
  GROUP_DETAIL_ROUTE,
  USER_ROLE,
  USER_ROLE_TYPES,
} from "../../../constants";
import "./styles.scss";

const Groups = () => {
  // STATES
  const [filteredData, setfilteredData] = useState([]);
  const [createGroupPreview, setCreateGroupPreview] = useState(false);
  const [editGroupPreview, setEditGroupPreview] = useState(false);
  const [deleteGroupPreview, setdeleteGroupPreview] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // CUSTOM DISATCH
  const [deleteGroup, deleteLoader] = CustomDispatch(deleteGroupRequest);
  const [editGroup] = CustomDispatch(editGroupRequest);
  const [getGroups, dataLoader] = CustomDispatch(getGroupsRequest);

  // REDUX DATA
  const { groups } = useSelector(({ groups }) => groups);
  const { groupAccess } = useSelector(({ user }) => user);

  // CONST VALS
  const navigate = useNavigate();
  const isSuperAdmin = USER_ROLE === USER_ROLE_TYPES.ADMIN;
  const marks = {
    0: "1k",
    100: "300k",
  };

  // HELPERS
  const getGroupsHelper = () => {
    const payload = {
      resource: "group",
      method: "list",
    };
    getGroups({
      payload,
    });
  };

  // HANDLERS
  const generateTableColumn = (data) => {
    const columns = [];
    data.forEach((element) => {
      if (element.isSuperAdmin && !isSuperAdmin) return;
      columns.push(element);
    });
    return columns;
  };
  const searchHandler = (e) => {
    const { value } = e.target;
    if (value === "") return setfilteredData(groups);
    const filtered = groups.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setfilteredData(filtered);
  };
  const editGroupPreviewHandler = (data) => {
    setEditGroupPreview(!editGroupPreview);
    setSelectedData(data);
  };
  const deleteGroupPreviewHandler = (data) => {
    setdeleteGroupPreview(!deleteGroupPreview);
    setSelectedData(data);
  };
  const closeModalPreviewHandler = () => {
    setSelectedData(null);
    setCreateGroupPreview(false);
    setEditGroupPreview(false);
    setdeleteGroupPreview(false);
  };
  const editTmpHandler = (data, tpm) => {
    const payload = {
      resource: "group",
      method: "update",
      details: { ...data, group_id: data.id, tpm },
    };
    editGroup({
      payload,
    });
  };
  const deleteGroupHandler = () => {
    const payload = {
      resource: "group",
      method: "delete",
      details: {
        group_id: selectedData.id,
      },
    };
    deleteGroup({
      payload,
      success() {
        closeModalPreviewHandler();
      },
    });
  };

  // CONST VALS
  const tableColumns = generateTableColumn([
    {
      title: "Name",
      dataIndex: "name",
      onCell: (record) => {
        return {
          onClick: () =>
            navigate(GROUP_DETAIL_ROUTE.replace(":groupslug", record.id)),
        };
      },
    },
    {
      title: "Tokens Per Minute (thousands)",
      isSuperAdmin: true,
      dataIndex: "",
      render: (data) => {
        return (
          <span className="customize-form">
            <Slider
              min={1}
              max={300}
              marks={marks}
              val={data.tpm}
              onAfterChange={(val) => {
                editTmpHandler(data, val);
              }}
            />
          </span>
        );
      },
    },
    {
      title: "",
      isSuperAdmin: true,
      dataIndex: "",
      render: (data) => {
        return (
          <span className="actions-box">
            <Tooltip title="Edit group">
              <FontAwesomeIcon
                onClick={() => editGroupPreviewHandler(data)}
                className="edit"
                icon={faEdit}
              />
            </Tooltip>
            <Tooltip title="Delete group">
              <FontAwesomeIcon
                onClick={() => deleteGroupPreviewHandler(data)}
                className="delete"
                icon={faTrashAlt}
              />
            </Tooltip>
          </span>
        );
      },
    },
  ]);

  // HOOKS
  useEffect(() => {
    setfilteredData(groups.filter((x) => !x.isDefault));
  }, [groups]);

  useEffect(() => {
    if (!groupAccess) return navigate(ERROR_ROUTE);
    getGroupsHelper();
  }, []);

  return (
    <div className="groups-wrapper">
      <PageTitle title="Groups Management">
        {isSuperAdmin && (
          <Button
            className="create-group-btn"
            onClick={() => setCreateGroupPreview(true)}
          >
            Create group
          </Button>
        )}
      </PageTitle>
      <div className="inner-detail">
        <Table
          title="Groups"
          height="calc(100vh - 430px)"
          isLoading={dataLoader}
          bordered
          isSearch
          searchHandler={searchHandler}
          data={filteredData}
          columns={tableColumns}
        />
      </div>
      <CreateGroup
        isEdit={editGroupPreview}
        data={selectedData}
        preview={createGroupPreview || editGroupPreview}
        previewHandler={closeModalPreviewHandler}
      />
      <ConfirmationModal
        title="Delete Group"
        description="Are you sure you want to delete this group?"
        isLoading={deleteLoader}
        preview={deleteGroupPreview}
        previewHandler={closeModalPreviewHandler}
        confirmBtnHandler={deleteGroupHandler}
      />
    </div>
  );
};

export default Groups;
