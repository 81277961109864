import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import { callRequest, PROJECT } from "../../config/web-service";
import { ALERT_TYPES, TOAST_MESSAGES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  createProjectRequest,
  createProjectSuccess,
  deleteProjectRequest,
  deleteProjectSuccess,
  editProjectRequest,
  editProjectSuccess,
  getProjectDetailRequest,
  getProjectDetailSuccess,
  getProjectMediaRequest,
  getProjectMediaSuccess,
  getProjectsRequest,
  getProjectsSuccess,
  restoreProjectRequest,
  restoreProjectSuccess,
  deleteMediaRequest,
  updateMediaRequest,
  updateMediaSuccess,
  addProjectUserRequest,
  addProjectUserSuccess,
  updateProjectUserRequest,
  updateProjectUserSuccess,
  removeProjectUserRequest,
  removeProjectUserSuccess,
  getProjectUsersRequest,
  getProjectUsersSuccess,
  getProjectMediaStatsSuccess,
  getProjectMediaStatsRequest,
} from "../slicers/project";
import {
  manipulateMediaListing,
  manipulateMediaStats,
  manipulateProjectDetailData,
  manipulateProjectListing,
} from "../../data-manipulator/project";

// GET PROJECTS LISTING
function* getProjectsListing() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getProjectsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        yield put(
          getProjectsSuccess(manipulateProjectListing(response?.data?.projects))
        );
      }
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET PROJECT DETAIL
function* getProjectDetail(action) {
  const {
    payload: { payload, onlyResponse, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    if (response.status && !onlyResponse) {
      yield put(
        getProjectDetailSuccess(
          manipulateProjectDetailData(response?.data?.project)
        )
      );
    }
    responseCallback?.(
      response?.status,
      response?.status
        ? manipulateProjectDetailData(response?.data?.project)
        : response
    );

    !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// CREATE PROJECT
function* createProject() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createProjectRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        const projectdata = manipulateProjectDetailData(
          response?.data?.project
        );
        yield put(createProjectSuccess(projectdata));
        responseCallback?.(true, projectdata);
      } else {
        responseCallback?.(false, response.data);
        !response.data?.project_id &&
          toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// UPDATE PROJECT
function* updateProject() {
  while (true) {
    const {
      payload: { payload, hideToast, responseCallback },
    } = yield take(editProjectRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        const projectdata = manipulateProjectDetailData(
          response?.data?.project
        );
        responseCallback?.(true, projectdata);
        yield put(editProjectSuccess(projectdata));
        if (!hideToast) toastAlert(TOAST_MESSAGES.CHANGES_SAVED);
      } else {
        toastAlert(response.message, ALERT_TYPES.ERROR);
        responseCallback?.(false, response.data);
      }
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// DELETE PROJECT
function* deleteProject() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(deleteProjectRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        yield put(deleteProjectSuccess(payload.details.project_id));
      }
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// RESTORE PROJECT
function* restoreProject() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(restoreProjectRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        const projectdata = manipulateProjectDetailData(
          response?.data?.project
        );
        yield put(restoreProjectSuccess(projectdata));
        responseCallback?.(true, projectdata);
      }
      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET PROJECT USERS
function* getProjectUsers() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getProjectUsersRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        yield put(getProjectUsersSuccess(response?.data?.users));
      }
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// ADD USER IN PROJECT
function* addProjectUser(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    responseCallback?.(response?.status, response);
    if (response.status) {
      yield put(addProjectUserSuccess(response.data?.users));
      toastAlert(TOAST_MESSAGES.ADD_USER_SUCCESS);
      return;
    }
    toastAlert(response?.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// ADD USER IN PROJECT
function* updateProjectUser(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    responseCallback?.(response?.status, response);
    if (response.status) {
      yield put(updateProjectUserSuccess(payload.details.users[0]));
      return;
    }
    toastAlert(response?.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// REMOVE USER FROM PROJECT
function* removeProjectUser(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    responseCallback?.(response?.status, response);
    if (response.status) {
      yield put(removeProjectUserSuccess(response.data?.users));
      toastAlert(TOAST_MESSAGES.USER_REMOVE_SUCCESS);
      return;
    }
    toastAlert(response?.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// GET MEDIA LISTING
function* getProjectMediaListing(action) {
  const {
    payload: { payload, onlyResponse, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    if (response.status) {
      responseCallback?.(true, manipulateMediaListing(response?.data));
      if (onlyResponse) return;
      yield put(getProjectMediaSuccess(manipulateMediaListing(response?.data)));
    } else {
      responseCallback?.(false, response);
    }

    !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// UPDATE MEDIA TAGS
function* updateProjectMedia() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(updateMediaRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status) {
        yield put(updateMediaSuccess(manipulateMediaListing(response?.data)));
        toastAlert(response.message || TOAST_MESSAGES.CHANGES_SAVED);
      }
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// DELETE PROJECT MEDIA
function* deleteProjectMedia() {
  while (true) {
    const {
      payload: { payload, hideToast, responseCallback },
    } = yield take(deleteMediaRequest.type);

    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      responseCallback?.(response?.status, response);
      if (!hideToast) toastAlert(TOAST_MESSAGES.CHANGES_SAVED);
      // toastAlert(
      //   response.message || TOAST_MESSAGES.FILE_DELETE_SUCCESS,
      //   response.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      // );
      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET MEDIA STATS
function* getProjectMediaStats(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    responseCallback?.(response?.status, response);
    if (response.status) {
      yield put(
        getProjectMediaStatsSuccess(manipulateMediaStats(response?.data))
      );
      return;
    }
    toastAlert(response?.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

export default function* root() {
  yield fork(getProjectsListing);
  yield takeEvery(getProjectDetailRequest.type, getProjectDetail);
  yield fork(createProject);
  yield fork(updateProject);
  yield fork(deleteProject);
  yield fork(restoreProject);
  yield fork(getProjectUsers);
  yield takeEvery(addProjectUserRequest.type, addProjectUser);
  yield takeEvery(updateProjectUserRequest.type, updateProjectUser);
  yield takeEvery(removeProjectUserRequest.type, removeProjectUser);
  yield takeEvery(getProjectMediaRequest.type, getProjectMediaListing);
  yield fork(updateProjectMedia);
  yield fork(deleteProjectMedia);
  yield takeEvery(getProjectMediaStatsRequest.type, getProjectMediaStats);
}
