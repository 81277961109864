import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image } from "antd";
import { Images } from "../../../../theme";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faPenRuler } from "@fortawesome/free-solid-svg-icons";
import { DASHBOARD_ROUTE, DEFAULT_USER_THUMB } from "../../../../constants";
import clsx from "clsx";
import { Dropdown } from "../../../../components";
import "./styles.scss";

const Option = ({ icon, label }) => {
  return (
    <div className="dropdown-option">
      <FontAwesomeIcon icon={icon} />
      <p>{label}</p>
    </div>
  );
};

const Header = ({
  sidebarPreview,
  setSidebarPreview,
  createProjectHandler,
}) => {
  // STATES
  const [profileOptionPreview, setprofileOptionPreview] = useState(false);

  // CONST VALS
  const createDropdownOptions = [
    {
      label: "Project",
      value: "project",
      icon: faPenRuler,
      onClick: createProjectHandler,
    },
  ];

  // REDUX DATA
  const { data: userdata } = useSelector(({ user }) => user);

  // HANDLERS
  const setprofileOptionPreviewHandler = () => {
    setprofileOptionPreview(!profileOptionPreview);
  };

  // HOOKS
  useEffect(() => {
    // function to close profile options preview on outside click
    document.addEventListener("click", (e) => {
      if (
        !e.target.closest(".profile-box") &&
        !e.target.closest(".profile-options")
      ) {
        setprofileOptionPreview(false);
      }
    });
  }, []);

  return (
    <header className="dashboard-header">
      <div className="left-box">
        <Link to={DASHBOARD_ROUTE}>
          <div className="logo-box">
            <img src={Images.Logo} alt="" />
            <h1>
              <span>MyAI</span>Builder
            </h1>
          </div>
        </Link>
      </div>
      <div className="right-box">
        <Dropdown
          className="create-dropdown"
          placeholder="Create new"
          options={createDropdownOptions}
          optionBox={Option}
          selectHandler={({ onClick }) => onClick()}
        />
        <div className="user-profile">
          <button
            className="profile-box"
            onClick={setprofileOptionPreviewHandler}
          >
            <span className="thumb">
              <Image
                preview={false}
                src={userdata?.photo_url}
                fallback={DEFAULT_USER_THUMB}
                alt={userdata?.name}
              />
            </span>
            <span className={clsx("caret", profileOptionPreview && "active")}>
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </button>
          <ul
            className={clsx(
              "profile-options",
              profileOptionPreview && "visible"
            )}
          >
            <li>
              <button onClick={setprofileOptionPreviewHandler}>
                {userdata?.name}
              </button>
            </li>
            {/* <li>
              <button onClick={setprofileOptionPreviewHandler}>Settings</button>
            </li> */}
            {/* <li>
              <button onClick={setprofileOptionPreviewHandler}>Logout</button>
            </li> */}
          </ul>
        </div>
      </div>
      <button
        className={clsx("toggle-btn", !sidebarPreview && "active")}
        onClick={setSidebarPreview}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default Header;
