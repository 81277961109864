import React, { useEffect, useState } from "react";
import BasicFields from "./basic-fields";
import { useSelector } from "react-redux";
import { Form, Switch } from "antd";
import { editProjectRequest } from "../../../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../../../helpers";
import { manipulateProjectBasicDetailPayload } from "../../../../../../data-manipulator/project";
import FormActions from "../form-actions";
import KnowledgeBase from "./knowledge-base";
import LabelBox from "../label-box";
import WebInterface from "./web-interface";
import { MODEL_OPTIONS } from "../../../../../../constants";
import "./styles.scss";

const BasicTab = ({ knowledgeBaseHandler }) => {
  // STATES
  const [webInterface, setwebInterface] = useState(false);
  const [applyPreview, setapplyPreview] = useState(false);

  // TEMP WORK
  const [switchFields, setswitchFields] = useState({
    time: false,
    date: false,
    caching: false,
    advanced: false,
    enableSearch: false,
  });

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);

  // CUSTOM DISPATCH
  const [editProject, isLoading] = CustomDispatch(editProjectRequest);

  // CONST VALS
  const [form] = Form.useForm();

  // HELPERS
  const setFieldsValuesHelper = () => {
    form.setFieldsValue(data);
    setwebInterface(data.webInterface);
    // TEMP WORK
    setswitchFields({
      time: data.time,
      date: data.date,
      caching: data.caching,
      advanced: data.advancedOptions,
      enableSearch: data.enableSearch,
    });
  };

  const editProjectHelper = (values) => {
    const model = MODEL_OPTIONS.find((item) => item.value === values.model);
    const details = {
      ...values,
      webInterface,
      ...switchFields,
      id: data.id,
      provider: model.provider,
    };
    const payload = {
      resource: "project",
      method: "update",
      details: manipulateProjectBasicDetailPayload(details),
    };
    editProject({
      payload,
      callback: (status) => setapplyPreview(!status),
    });
  };

  // HANDLERS
  const fieldChangeHandler = (status = true) => {
    setapplyPreview(status);
  };
  const webInterfaceHandler = () => {
    fieldChangeHandler();
    setwebInterface(!webInterface);
  };

  // TEMP WORK
  const switchFieldsHandler = (name) => {
    fieldChangeHandler();
    setswitchFields({ ...switchFields, [name]: !switchFields[name] });
  };

  const applyHandler = () => {
    form
      .validateFields()
      .then((values) => {
        fieldChangeHandler(false);
        editProjectHelper(values);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => {
    if (data) setFieldsValuesHelper();
  }, [data]);

  return (
    <Form
      form={form}
      className="basic-tab customize-form"
      onFieldsChange={() => fieldChangeHandler()}
    >
      <BasicFields
        // TEMP WORK
        switchFields={switchFields}
        switchFieldsHandler={switchFieldsHandler}
      />
      <hr />
      <KnowledgeBase
        switchFields={switchFields}
        switchFieldsHandler={switchFieldsHandler}
        knowledgeBaseHandler={knowledgeBaseHandler}
      />
      <hr />
      <div className="label-box">
        <LabelBox title="Enable web app" name="webInterface" />
        <Switch
          id="webInterface"
          checked={webInterface}
          onChange={webInterfaceHandler}
        />
      </div>
      {webInterface && <WebInterface data={data} />}
      <FormActions
        applyLoader={isLoading}
        applyPreview={applyPreview}
        applyHandler={applyHandler}
      />
    </Form>
  );
};

export default BasicTab;
