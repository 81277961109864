import React, { useEffect } from "react";
import { Loader } from "../../components";
import { getUserDetailsRequest } from "../../redux/slicers/user";
import { getUserAsurite } from "../../utils";
import { CustomDispatch } from "../../helpers";
import ChatWidget from "../../components/chat-widget";

const PrivateSharedLayout = ({ children }) => {
  // CONST VALS
  const userAsurite = getUserAsurite();

  // CUSTOM DISPATCH
  const [getUserDetail, userLoader] = CustomDispatch(
    getUserDetailsRequest,
    true
  );

  // HELPERS
  const getUserDetailHelper = () => {
    const payload = {
      resource: "user",
      method: "describe",
      details: {
        users: [userAsurite],
      },
    };
    getUserDetail({ payload, loggedinUser: true });
  };

  // HOOKS
  useEffect(() => {
    if (!userAsurite) return;
    getUserDetailHelper();
    // getUserAccessHelper();
  }, [userAsurite]);

  if (userLoader) return <Loader />;
  return (
    <>
      {children}
      <ChatWidget />
    </>
  );
};

export default PrivateSharedLayout;
