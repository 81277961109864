import React, { useEffect, useState } from "react";
import { Button, ConfirmationModal } from "../../../../../../components";
import ProjectUrlsFilter from "./filter-box";
import ProjectUrlsListing from "./urls-listing";
import { useSelector } from "react-redux";
import { CustomDispatch } from "../../../../../../helpers";
import {
  deleteMediaRequest,
  getProjectMediaRequest,
  updateMediaRequest,
} from "../../../../../../redux/slicers/project";
import { cloneDeepItem, toastAlert } from "../../../../../../utils";
import { generateUploadURLRequest } from "../../../../../../redux/slicers/general";
import {
  manipulateAutoUpdateMediaData,
  manipulateUpdateUrlPayload,
} from "../../../../../../data-manipulator/project";
import AddUrlForm from "./add-form";
import { ALERT_TYPES, TOAST_MESSAGES } from "../../../../../../constants";
import "./styles.scss";

const ProjectUrls = ({
  urlsLoader,
  allUrls,
  urls,
  setUrls,
  getUploadedUrls,
}) => {
  // STATES
  const [searchText, setSearchText] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [deletedUrls, setDeletedUrls] = useState([]);
  const [addUrlPreview, setAddUrlPreview] = useState(false);
  const [savePreview, setsavePreview] = useState(false);
  const [deleteUrlsPreview, setdeleteUrlsPreview] = useState(false);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const projectTags = useSelector(({ project }) => project.projectTags);

  // CUSTOM DISPATCH
  const [getProjectUrlsInterval] = CustomDispatch(getProjectMediaRequest);
  const [generateUrl, uploadLoader] = CustomDispatch(generateUploadURLRequest);
  const [deleteMedia, deleteLoader] = CustomDispatch(deleteMediaRequest);
  const [updateMedia, updateLoader] = CustomDispatch(updateMediaRequest);

  // CONST VALS
  const deleteButtonPreview = filteredUrls.some((item) => item.selected);
  const deleteAllLoader = deleteLoader && deleteUrlsPreview;
  const deleteUrlLoader = deleteLoader && !deleteUrlsPreview;

  // HELPERS
  const getProjectUrlsHelper = (clearHandler = () => {}) => {
    try {
      const payload = {
        resource: "data",
        method: "list",
        details: {
          project_id: data.id,
          db_type: data.database,
        },
      };
      getProjectUrlsInterval({
        payload,
        onlyResponse: true,
        success(res) {
          const tempMedia = [...urls];
          tempMedia.forEach((item, index) => {
            const newdata = res?.media?.urls.findIndex(
              (x) => x.name === item.name
            );
            if (newdata !== -1) {
              tempMedia[index] = manipulateAutoUpdateMediaData(
                tempMedia[index],
                res?.media?.urls[newdata]
              );
            }
          });
          setUrls(tempMedia);
          const isPending = res?.media?.urls.some((item) => item.isPending);
          if (!isPending) clearHandler();
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const generateUrlHelper = (urls = []) => {
    const payload = {
      resource: "data",
      method: "upload",
      details: {
        project_id: data?.id,
        db_type: data?.database,
        urls: urls.map((item) => manipulateUpdateUrlPayload(item)),
      },
    };
    generateUrl({
      payload,
      success: () => {
        setAddUrlPreview(false);
        getUploadedUrls();
      },
      callback: (status) => setsavePreview(!status),
    });
  };

  const deleteUrlHelper = (toastPreview, newUrls, onSuccess = () => {}) => {
    const payloadMedia = newUrls ?? deletedUrls;
    const payload = {
      resource: "data",
      method: "delete",
      details: {
        project_id: data.id,
        db_type: data.database,
        urls: payloadMedia.map((x) => {
          const item = allUrls.find((y) => y.name === x);
          return manipulateUpdateUrlPayload(item, true);
        }),
      },
    };
    deleteMedia({
      payload,
      hideToast: !toastPreview,
      callback: (status) => !newUrls && setsavePreview(!status),
      success: () => {
        setDeletedUrls([]);
        onSuccess();
      },
    });
  };

  const updateUrlHelper = () => {
    const updatedurls = urls.filter((item) => item.isUpdated);
    if (updatedurls.length < 1) return;
    const details = {
      project_id: data?.id,
      db_type: data?.database,
      urls: updatedurls.map((item) => manipulateUpdateUrlPayload(item)),
    };
    const payload = {
      resource: "data",
      method: "update",
      details,
    };
    updateMedia({
      payload,
      callback: (status) => {
        setsavePreview(!status);
      },
    });
  };

  // HANDLERS
  const setsavePreviewHandler = () => setsavePreview(true);

  const addUrlPreviewHandler = () => setAddUrlPreview(!addUrlPreview);

  const deleteUrlsPreviewHandler = () =>
    setdeleteUrlsPreview(!deleteUrlsPreview);

  const searchTextHandler = (text) => {
    setSearchText(text);
  };

  const selectTagHandler = (tag) => {
    const temp = [...selectedTags];
    const index = temp.findIndex((item) => item === tag);
    if (index !== -1) temp.splice(index, 1);
    else temp.push(tag);
    setSelectedTags(temp);
  };

  const selectFilterHandler = (filter) => {
    const temp = [...selectedFilters];
    const index = temp.findIndex((item) => item === filter);
    if (index !== -1) temp.splice(index, 1);
    else temp.push(filter);
    setSelectedFilters(temp);
  };

  const addUrlHandler = (newUrl) => {
    const isExist = urls.some((item) => item.name === newUrl.name);
    if (isExist) return toastAlert(TOAST_MESSAGES.URL_EXIST, ALERT_TYPES.ERROR);
    generateUrlHelper([newUrl]);
  };

  const deleteUrlHandler = (url, onSucess) => {
    const temp = [...urls];
    const urlsIndex = temp.findIndex((item) => item.name === url.name);
    if (urlsIndex !== -1) temp.splice(urlsIndex, 1);
    deleteUrlHelper(true, [url.name], () => {
      setUrls(temp);
      onSucess();
    });
  };

  const deleteSelectedUrlsHandler = () => {
    const temp = [...urls];
    const filtered = temp.filter((item) => !item.selected);
    const deleteurls = temp.filter((item) => item.selected && !item.isCreated);
    if (deleteurls.length) {
      deleteUrlHelper(
        true,
        deleteurls.map((x) => x.name),
        () => {
          setUrls(filtered);
          setdeleteUrlsPreview(false);
        }
      );
      return;
    }
    setUrls(filtered);
    setdeleteUrlsPreview(false);
  };

  const saveChangesHandler = () => {
    const updateMedia = urls.filter((item) => item.isUpdated).length > 0;
    if (deletedUrls.length > 0) deleteUrlHelper(!updateMedia);
    if (updateMedia) updateUrlHelper();
  };

  // HOOKS
  useEffect(() => {
    if (!urls.length) return setFilteredUrls([]);
    let temp = cloneDeepItem(urls);
    if (searchText) {
      temp = temp.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (selectedTags.length) {
      temp = temp.filter((item) =>
        item.tags.some((tag) => selectedTags.includes(tag))
      );
    }
    if (selectedFilters.length) {
      temp = temp.filter((item) =>
        selectedFilters.every((filter) => item[filter])
      );
    }
    setFilteredUrls(temp);
  }, [urls, searchText, selectedTags, selectedFilters]);

  useEffect(() => {
    let interval;
    const handleMediaChange = () => {
      const pendingurls = urls?.filter((item) => item.isPending).length;
      if (pendingurls > 0) {
        interval = setInterval(() => {
          getProjectUrlsHelper(() => {
            clearInterval(interval);
          });
        }, 10000); // 10 seconds
      }
    };
    handleMediaChange();
    return () => interval && clearInterval(interval);
  }, [urls]);

  return (
    <div className="project-urls">
      <h4 className="sub-title">URLs</h4>
      <p className="sub-description">
        Add any ASU website URL to your knowledge base. It will be automatically
        scraped and indexed. Choose up to 4 layers of pages and decide if
        documents and PDFs should also be included.
      </p>
      <Button
        invertedTheme
        boldText
        className="add-btn"
        onClick={addUrlPreviewHandler}
      >
        Add URL
      </Button>
      <ProjectUrlsFilter
        tags={projectTags.urls}
        searchText={searchText}
        searchHandler={searchTextHandler}
        selectedTags={selectedTags}
        selectTag={selectTagHandler}
        selectedFilters={selectedFilters}
        selectFilter={selectFilterHandler}
      />
      <ProjectUrlsListing
        isLoading={urlsLoader}
        urls={filteredUrls}
        setUrls={setUrls}
        deleteUrls={deleteUrlHandler}
        deleteLoader={deleteUrlLoader}
        enableSavePreview={setsavePreviewHandler}
      />
      <div className="action-box">
        {deleteButtonPreview && !deleteAllLoader && (
          <Button
            boldText
            className="delete-btn"
            onClick={deleteUrlsPreviewHandler}
          >
            Delete selected url's
          </Button>
        )}
        {savePreview && (
          <Button
            boldText
            invertedTheme
            isLoading={updateLoader}
            onClick={saveChangesHandler}
            className="save-btn"
          >
            Save changes
          </Button>
        )}
      </div>
      {/* ADD URL MODAL */}
      <AddUrlForm
        preview={addUrlPreview}
        previewHandler={addUrlPreviewHandler}
        isLoading={uploadLoader}
        submit={addUrlHandler}
      />
      {/* DELETE URL MODAL */}
      <ConfirmationModal
        title="Delete URL's"
        description="Are you sure you want to delete selected url's?"
        preview={deleteUrlsPreview}
        isLoading={deleteAllLoader}
        previewHandler={deleteUrlsPreviewHandler}
        confirmBtnHandler={deleteSelectedUrlsHandler}
      />
    </div>
  );
};

export default ProjectUrls;
