const ENV_CONFIG = {
  prod: {
    service: "https://auth-main.aiml.asu.edu",
    appName: "platform",
    appId: "fqeeVg8HpDsBLPp4uvGfZy",
    appUrl: "https://api-main.aiml.asu.edu/",
    socketUrl: "wss://apiws-main.aiml.asu.edu/",
    projectPublicHost: "https://app.aiml.asu.edu/",
    modelComparisionHost: "http://compare.aiml.asu.edu/",
    projectId: "de765543f0e340ceba0c13c95ea55aed",
  },
  beta: {
    service: "https://auth-main-beta.aiml.asu.edu",
    appName: "platform",
    appId: "fqeeVg8HpDsBLPp4uvGfZy",
    appUrl: "https://api-main-beta.aiml.asu.edu/",
    socketUrl: "wss://apiws-main-beta.aiml.asu.edu/",
    projectPublicHost: "https://app-beta.aiml.asu.edu/",
    modelComparisionHost: "http://compare-beta.aiml.asu.edu/",
    projectId: "de765543f0e340ceba0c13c95ea55aed",
  },
  poc: {
    service: "https://auth-main-poc.aiml.asu.edu",
    appName: "platform",
    appId: "fqeeVg8HpDsBLPp4uvGfZy",
    appUrl: "https://api-main-poc.aiml.asu.edu/",
    socketUrl: "wss://apiws-main-poc.aiml.asu.edu/",
    projectPublicHost: "https://app-poc.aiml.asu.edu/",
    modelComparisionHost: "http://compare-poc.aiml.asu.edu/",
    projectId: "de765543f0e340ceba0c13c95ea55aed",
  },
};

export default ENV_CONFIG;
